<template>
  <div id="app">
    <router-view/>
    <fullScreenLoadingView></fullScreenLoadingView>
  </div>
</template>

<script>
import fullScreenLoadingView from '@/views/fullScreenLoadingView.vue'
export default {
  name: 'App',
  components: {
    fullScreenLoadingView
  },
  data(){
    return {
      
    }
  },
  watch:{
    $route(to,from){ //監聽路由
      
    }
  },
  methods:{},
  created(){

    // if ( !this.$shared.checkIsLogin() ){
    //   return alert('你有登入過！這裡要轉址到別的地方去')
    // }
  }
}
</script>
<style>
.form-control{
    border-radius: 2rem;
    background-color: #eaf3fe;
    border-color: #ffffff;
}
.third-party{
    list-style: none;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
}
.third-party > li {
    border-radius: 50%;
    border: 1px solid #f8f8f8;
    width: 3.25rem;
    height: 3.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f8ff;
    box-shadow: 1px 1px 3px #00000024;
}
</style>
