import { createStore } from 'vuex'

const store =  createStore({
  state () {
    return {
      loginStatus:true,
      loadingStatus:false,
      progressStatue:false,
      progressValue:0,
      actionResult:null,
      progress:-1,
      userData:null,
      tableDefaultValue:null
    }
  },
  getters: {
  },
  mutations: {
    setTableDefaultValue(state,data){
      
      state.tableDefaultValue = data
    },
    initTableDefaultValue(state){
      state.tableDefaultValue = null
    },
    setBreadcrumb(state,data){
      state.breadcrumbView = data
    },
    updateBreadcrumb(state,data){
      state.breadcrumbView.path[data.index][data.key] = data.value
    },
    setLoadingStatus(state, status) {
      state.loadingStatus = status;
    },
    setProgress(state, value) {
      state.progress = value;
    },
    setUserinfo (state,data) {
        state.userData = null
        state.userData = data.userData
    },
    getUserInfo (state) {
      console.log(`getUserinfo:` , state)
      // 判斷 state.userData == 空物件回傳null
      return state.userData
    },
    userLogOut(state){
        console.log('userLogOut')
        state.userData = null
    }
  },
  actions: {
  },
  modules: {
  }
})

// 訂閱者邏輯
store.subscribe((mutation, state) => {
  if (mutation.type === 'setLoadingStatus' && state.loginStatus === false) {
    store.commit('setProgress', -1);
  }
});


export default store