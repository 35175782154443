import $ from "jquery"
import modals from "bootstrap/js/dist/modal"
import CryptoJS from "crypto-js";
import i18n from '@/i18n'
class shareds {
    //物件建立時產生
    constructor(){
    }

    imagePath(image){
        return require(`@/assets/image/${i18n.global.locale.value}/${image}`)
    }

    //初始化牙齒陣列
    initTeethAry(){
        let ary = {}
        for (let index = 18; index >= 11; --index) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 21; index <= 28; index++) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 38; index >= 31; --index) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        for (let index = 41; index <= 48; index++) {
            ary[index] = {
                name:index.toString(),
                value:0,
                class:''
            }
        }
        console.log(ary)

        return ary
    }
    
    //初始化狀態陣列
    orderOrderStatus(){
        
        var status = []
        status[0] = '訂單建立中'
        status[1] = '訂單開放媒合'
        status[2] = '訂單已媒合未確認'
        status[3] = '訂單齒模寄送'
        status[4] = '訂單等待技工所接受'
        status[5] = '訂單已確認'

        status[11] = '訂單口掃中'
        status[12] = '訂單設計中'
        status[13] = '訂單製作中途確認'
        status[14] = '訂單加工&QC中'
        status[15] = '訂單製作完成待診所確認'

        status[91] = '訂單技工所確認取消'
        status[98] = '訂單取消'
        status[99] = '訂單完成'
        
        return status
    }

    isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    //加密
    encryptionUserInfo(data){
        console.log(data)
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var iv = CryptoJS.enc.Utf8.parse("");
        let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(JSON.stringify(data)), 
            key, { 
                iv:iv,
                mode: CryptoJS.mode.ECB, 
                padding: CryptoJS.pad.Pkcs7
            }
        );
        // console.log("encrypted=",encrypted.ciphertext.toString(CryptoJS.enc.Base64))
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    }

    decryptionUserInfo(data){
        var key = CryptoJS.enc.Utf8.parse("c4e1u12d45e83f78535dkb86bah50f4a");
        var decrypt = CryptoJS.AES.decrypt(
            data, 
            key,
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return JSON.parse( decrypt.toString( CryptoJS.enc.Utf8 ) )
    }

    getCompName(compType){ //取得title
        // console.log('getCompName=',compType)
        let titleName = ''
        switch ( compType ) {
            case 1:
                titleName = '管理後台'
                break;
            case 2:
                titleName = '掛號系統'
                break;
            case 3:
                titleName = '診所'
                break;
            case 4:
                titleName = '技工所'
                break;
            default:
                break;
        }
        // console.log('titleName=',titleName)
        return titleName
    }

    gotoSystemView(comp_id){
        let url = ''
        console.log('env=',process.env)
        switch ( comp_id ) {
            case 1: //admin
                url = process.env.VUE_APP_ADMIN_URL+'/enter/'
                break;
            case 2: //his
                url = process.env.VUE_APP_HIS_URL+'/enter/'
                break;
            case 3: //clinic
                url = process.env.VUE_APP_CLINCI_URL+'/enter/'
                break;
            case 4: //dentlab/
                url = process.env.VUE_APP_DENTLAB_URL+'/enter/'
                break;
            case 5: //dentlab/
                url = process.env.VUE_APP_MAIN_URL
                break;
            default:
                break;
        }
        return url
    }

    checkIsLogin(){
        const userData = localStorage.getItem('dcp-user-info') ? JSON.parse(localStorage.getItem('dcp-user-info')) : null
        if (
            userData &&
            !( typeof userData === 'object' && Object.keys(userData).length === 0 )
        ){
            return true
        }
        return true
    }
}
export default shareds