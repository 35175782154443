const routes = [
    {
        path:'/',
        name:'mainLayout',
        component: () => import(/* webpackChunkName: "about" */ '@/views/public/mainLayoutView.vue'),
        children:[{
            path:'',
            name:'empty',
            component: () => import(/* webpackChunkName: "about" */ '@/views/public/empty.vue'),
            props: { message: '' }
        },
        {
            path:'/login/:com_type',
            name:'login',
            component: () => import(/* webpackChunkName: "about" */ '@/views/login/loginView.vue')
        },{
            path:'/register/:com_type',
            name:'register',
            component: () => import(/* webpackChunkName: "about" */ '@/views/register/registerView.vue')
        },{
            path:'/forgetPassword/:com_type',
            name:'forgetPassword',
            component: () => import(/* webpackChunkName: "about" */ '@/views/fgpwd/fgpwdView.vue')
        },{
            path:'/registerInvite/:com_type/:token',
            name:'registerInvite',
            component: () => import(/* webpackChunkName: "about" */ '@/views/register/registerInviteView.vue')
        }
        // ,{
        //     path:'/admin',
        //     name:'adminLoginView',
        //     component: () => import(/* webpackChunkName: "about" */ '../views/login/adminloginView.vue')
        // },
        ]
    },{
        path:'/registerNewCompany/:com_type',
        name:'registerNewCompany',
        component: () => import(/* webpackChunkName: "about" */ '@/views/register/registerNewCompanyView.vue')
    },{
        path:'/privacyPolicy',
        name:'privacyPolicy',
        component: () => import(/* webpackChunkName: "about" */ '@/views/privacyPolicy.vue')
    },{
        path:'/termsService',
        name:'termsService',
        component: () => import(/* webpackChunkName: "about" */ '@/views/termsService.vue')
    }
    
]
export default routes