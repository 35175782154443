import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import i18n from '@/i18n'
import routes from './router/index'
import store from '@/store'
import ApiService from '@/assets/js/apiService'; // 引入 ApiService 實例
import Parameters from '@/assets/js/parameters'
import shareds from '@/assets/js/shared'

const router = createRouter({
    base: process.env.VUE_APP_BASE_APP_URL,
    history: createWebHistory(process.env.VUE_APP_BASE_APP_URL),
    routes,
    mode:'history'
})

const app = createApp(App);

const apiService = new ApiService(app);
const parameter = new Parameters()
const shared = new shareds()

app.config.globalProperties.$api = apiService;
app.config.globalProperties.$parameter = parameter;
app.config.globalProperties.$shared = shared;

apiService.debug = app.config.globalProperties.$debug
apiService.router = router
apiService.store = store

app.use(router);
app.use(store);
app.use(i18n)
app.mount('#app');
  
