import $ from "jquery"
import CryptoJS from "crypto-js";

class ApiService {
    //物件建立時產生
    constructor(data){
        if ( data && data.api_url ){
            this.url = data.api_url
        }else{
            this.url = process.env.VUE_APP_API_URL
        }

        console.log('ApiService->',this.url)

        this.key = ''
        this.header = {}
        this.isDecryption = false
    }
    init(data){ //初始化header
        this.header = {
            token:data.token
        }
        this.key = this.getUserToken(data.token)
    }
    //user Token 處理成32長度
    getUserToken(token){
        console.log('getUserToken token->',token)
        let hToken = token.substr(0,16)
        console.log('getUserToken hToken ['+hToken.length+']->',hToken)
        let fToken = token.substr(token.length-16,token.length)
        console.log('getUserToken hToken ['+fToken.length+']->',fToken)
        return hToken+fToken
    }
    //解密
    AES_ECB_DECRYPT(token) {
        var decrypt = CryptoJS.AES.decrypt(
            token, 
            CryptoJS.enc.Utf8.parse(this.key),
            {
                mode: CryptoJS.mode.ECB,
                padding: CryptoJS.pad.Pkcs7
            }
        );
        return decrypt.toString( CryptoJS.enc.Utf8 )
    }
    //資料加密
    AES_ECB_ENCRYPT(parameter){
        console.log('AES_ECB_ENCRYPT = ',this.header.token)
        let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(parameter)), CryptoJS.enc.Utf8.parse(this.key), { 
            iv:CryptoJS.enc.Utf8.parse(this.iv),
            mode: CryptoJS.mode.ECB, 
            padding: CryptoJS.pad.Pkcs7
        }); 
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64)
    }
    //api請求body資料加密
    apiDataEncrypt(parameter){
        if ( this.isDecryption ){ //需要加密
            parameter = {
                data:this.AES_ECB_ENCRYPT(parameter)
            }
            parameter = JSON.stringify(parameter)
        }else{ //資料不需要加密
            parameter = JSON.stringify(parameter)
        }
        return parameter
    }
    //api回應資料解密
    apiDataDecrypt(parameter){
        if ( this.isDecryption ){ //需要解密
            parameter = JSON.parse( this.AES_ECB_DECRYPT(parameter.data) )
        }
        return parameter
    }
    //處理login&註冊時產生token
    userInputEncrypt(){
        let token = CryptoJS.lib.WordArray.random(128/8).toString(CryptoJS.enc.Hex)
        return token
    }
    //get加密
    getMethodEncryp(parameter){
        if ( this.isDecryption ){ //需要加密
            parameter = {
                data:this.AES_ECB_ENCRYPT(parameter)
            }
            parameter = "data="+parameter
        }else{ //資料不需要加密
            // parameter = JSON.stringify(parameter)
            let url = ''
            Object.keys(parameter).forEach((key) => {
                url = url+key+'='+parameter[key]+'&'
            });
            parameter =  url.substring(0, url.length - 1);
        }
        return parameter
    }
    //執行POST
    postApi(data){ 
        
        let self = this
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'POST',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            contentType : 'application/json; charset=utf-8', // 要送到server的資料型態
            data: self.apiDataEncrypt(data.parameter),
            success: function(res){
                console.log(res)
                // 成功以後會執行這個方法
                res = self.apiDataDecrypt(res)
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            xhr: function(){
                var xhr = new window.XMLHttpRequest(); // 建立xhr(XMLHttpRequest)物件
                xhr.upload.addEventListener("progress", function(progressEvent){ // 監聽ProgressEvent
                    if (progressEvent.lengthComputable) {
                        var percentComplete = progressEvent.loaded / progressEvent.total;
                        var percentVal = Math.round(percentComplete*100);
                        if ( data.progress ){
                            console.log("上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total); 
                            data.progress({
                                mag:"上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total,
                                loaded:progressEvent.loaded,
                                total:progressEvent.total,
                                percentComplete:percentComplete,
                                percentVal:percentVal
                            })
                        }   
                    }
                },false);
                return xhr; // 注意必須將xhr(XMLHttpRequest)物件回傳
            },
            error: function(error){
                console.log(`error ${data.url}` , error)
                alert('error[500] : server error')
                data.success({code:500})
            }
        })
    }
    //執行PUT
    putApi(data){
        let self = this
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'PUT',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            contentType : 'application/json; charset=utf-8', // 要送到server的資料型態
            data: self.apiDataEncrypt(data.parameter),
            success: function(res){
                console.log(res)
                // 成功以後會執行這個方法
                res = self.apiDataDecrypt(res)
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            xhr: function(){
                var xhr = new window.XMLHttpRequest(); // 建立xhr(XMLHttpRequest)物件
                xhr.upload.addEventListener("progress", function(progressEvent){ // 監聽ProgressEvent
                    if (progressEvent.lengthComputable) {
                        var percentComplete = progressEvent.loaded / progressEvent.total;
                        var percentVal = Math.round(percentComplete*100);
                        if ( data.progress ){
                            console.log("上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total); 
                            data.progress({
                                mag:"上傳進度為：" + progressEvent.loaded + " of " + progressEvent.total + " bytes" + '；百分比為：' + progressEvent.loaded/progressEvent.total,
                                loaded:progressEvent.loaded,
                                total:progressEvent.total,
                                percentComplete:percentComplete,
                                percentVal:percentVal
                            })
                        }   
                    }
                },false);
                return xhr; // 注意必須將xhr(XMLHttpRequest)物件回傳
            },
            error: function(){
                alert('error[500] : serveer error')
                data.success({code:500})
            }
        })
    }
    //執行GET
    getApi(data){
        // {url,parameter,callback}
        let self = this
        if (data.parameter){
            data.url = data.url+"?"+this.getMethodEncryp(data.parameter)
        }
        $.ajax({
            url: data.url,              // 要傳送的頁面
            method: 'GET',               // 使用 POST 方法傳送請求
            dataType: 'json',             // 回傳資料會是 json 格式
            headers:self.header,
            success: function(res){
                // 成功以後會執行這個方法
                if (res.code < 0 ){
                    alert('error['+res.code +'] : ' + res.message )
                }
                data.success(res)
            },
            error: function(){
                alert('error[500] : serveer error')
                data.success({code:500})
            }
        })
    }

    /*
    api區
    */
    //登入
    async login(parameter){
        // this.header.token = this.userInputEncrypt()
        // this.key = this.header.token
        //處理密碼加密
        const hash = CryptoJS.MD5(parameter.pwd);
        parameter.pwd = hash.toString()
        return new Promise((resolve) => {
            //呼叫post方法
            this.postApi({
                url:this.url+"users/login",
                parameter:parameter,
                success:resolve
            })
        })
    }
    //忘記密碼
    async forgetPwd(parameter){
        return new Promise((resolve) => {
            //呼叫post方法
            this.putApi({
                url:this.url+"users/forgetPwd",
                parameter:parameter,
                success:resolve
            })
        })
        
    }
    //註冊帳號
    async register(parameter){
        // this.header.token = this.userInputEncrypt()
        // this.key = this.header.token
        //處理密碼加密
        const hash = CryptoJS.MD5(parameter.pwd);
        parameter.pwd = hash.toString()
        return new Promise((resolve) => {
            //呼叫post方法
            this.putApi({
                url:this.url+"users/addAcc",
                parameter:parameter,
                success:resolve
            })
        })
        
    }
    //檢查GUINo統編
    async checkGUINo(parameter){
        return new Promise((resolve) => {
            //呼叫post方法
            this.postApi({
                url:this.url+"users/checkGUINo",
                parameter:parameter,
                success:resolve
            })
        })
    }
    //使用者 - 檢查醫事編號
    async checkMedicalNo(parameter){
        return new Promise((resolve) => {
            //呼叫post方法
            this.postApi({
                url:this.url+"users/checkMedicalNo",
                parameter:parameter,
                success:resolve
            })
        })
    }
    //檢查統一編號
    getGovGUIDNo(parameter,callback){
        this.getApi({
            url:"https://data.gcis.nat.gov.tw/od/data/api/9D17AE0D-09B5-4732-A8F4-81ADED04B679?$format=json&$filter=Business_Accounting_NO eq "+parameter.guino,
            success:function(res){
                callback(res)
            }
        })
    }
    //檢查醫事編號
    //https://data.nhi.gov.tw//api/v1/rest/datastore/A21030000I-D21004-009?filters={Hosp_ID:醫事編號}
    getGovMedical(parameter,callback){
        this.getApi({
            url:"https://data.nhi.gov.tw//api/v1/rest/datastore/A21030000I-D21004-009?filters={Hosp_ID:"+parameter.guino+"}",
            success:function(res){
                callback(res)
            }
        })
    }
    //註冊新公司
    async addComAndUpdUser(parameter){
        return new Promise((resolve) => {
            //呼叫post方法
            this.putApi({
                url:this.url+"users/addComAndUpdUser",
                parameter:parameter,
                success:resolve
            })
        })
        
    }
    //重取使用者資料
    async getUserByEmail(){
        return new Promise((resolve) => {
            //呼叫post方法
            this.getApi({
                url:this.url+"users/getUserByEmail",
                success:resolve
            })
        })
        
    }
    //使用者 - 檢查邀請
    async checkInvite(parameter){
        return new Promise((resolve) => {
            //呼叫post方法
            this.postApi({
                url:this.url+"users/checkInvite",
                parameter:parameter,
                success:resolve
            })
        })
        
    }
    //檢查掛號系統是否存在
    getHisByToken(parameter,callback){
        //呼叫post方法
        this.getApi({
            url:this.url+"his/getHisByToken",
            parameter:parameter,
            success:function(res){
                //(0:success,-100:code error,-101:req data empty,-102:sql error,-2:email exisit)
                callback(res)
            }
        })
    }

}
export default ApiService