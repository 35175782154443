import i18n from '@/i18n'
class Parameters {
    constructor(){
    }

    viewData = [{
        id:3,
        title: `simple-dental-clinic`,
        subTitle: `dental-clinic`,
        chargingMode:false,
        chargingModeString: `free`,
        tips: `dental-clinic-tips`,
    },{
        id:4,
        title: `simple-dental-laboratory`,
        subTitle: `dental-laboratory`,
        chargingMode: `billing-by-order`,
        chargingMode:true,
        chargingModeString: `billing-by-order`,
        tips: `dental-laboratory-tips`,
    },{
        id:2,
        title: `simple-his-system`,
        subTitle: `his-system`,
        chargingMode:true,
        chargingModeString:"",
        tips: `his-system-tips`,
    }]
}
export default Parameters